import _defineProperty from "/home/opter/repo/code/cloudsee-web/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
var _PARTY_SETTING_ITEM_V;
/*eslint-disable*/

// 组织架构类型-甲方公司
export var ORG_TYPE_PARTY = 1;
// 组织架构类型-服务主体
export var ORG_TYPE_COMPANY = 2;

// 行政部门信息
export var ADMINISTRATIVE_DEPT = 1;
// 物业部门信息
export var PROPERTY_DEPT = 2;

// 行政岗位信息
export var ADMINISTRATIVE_JOB = 1;
// 物业岗位信息
export var PROPERTY_JOB = 2;

// 行政职工用户信息
export var ADMINISTRATIVE_USER = 1;
// 物业职工用户信息
export var PROPERTY_USER = 2;
// 系统用户信息
export var SYSTEM_USER = 3;

// 组织架构类型map
export var ORG_TYPE_MAP = _defineProperty(_defineProperty({}, ORG_TYPE_PARTY, 'party'), ORG_TYPE_COMPANY, 'company');

/**
 * 平台类型
 * @type {string}
 */
// 云服务平台
export var PLATFORM_TYPE_SERVICE_WEB = 'SERVICE_WEB';
// BI大屏
export var PLATFORM_TYPE_BIG_SCREEN_WEB = 'BIG_SCREEN_WEB';

/**
 * 个性化设置-模块编码
 * @type {string}
 */
// 物料出库
export var PARTY_SETTING_MODULE_CODE_MATERIAL_EXPORT = 'stock_warehouse_export_bill';
// 监管大屏
export var PARTY_SETTING_MODULE_CODE_BIG_SCREEN = 'big_screen';
// 临时巡查
export var PARTY_SETTING_MODULE_CODE_TEMP_TASK = 'work_temp_task';
// 考勤管理
export var PARTY_SETTING_MODULE_CODE_OSS_ATTENDANCE = 'oss_attendance';

/**
 * 个性化设置-个性化设置选项编码
 * @type {string}
 */
// 出库条件设置
export var PARTY_SETTING_ITEM_CODE_BILL_QUANTITY_ROLE = 'bill_quantity_role';
// 二维码-扫码-发起报修
export var PARTY_SETTING_ITEM_CODE_EQUIPMENT_ORDER_ADD = 'equipment_order_add';
// 二维码-扫码-基本信息
export var PARTY_SETTING_ITEM_CODE_EQUIPMENT_BASE_INFO = 'equipment_info';
// 二维码-扫码-巡检记录
export var PARTY_SETTING_ITEM_CODE_EQUIPMENT_INSPECT_RECORD = 'equipment_inspect';
// 二维码-扫码-维保记录
export var PARTY_SETTING_ITEM_CODE_EQUIPMENT_MAINTENANCE_RECORD = 'equipment_maintenance';
// 二维码-扫码-维修记录
export var PARTY_SETTING_ITEM_CODE_EQUIPMENT_REPAIRE_RECORD = 'equipment_repair';
// BI大屏-监管大屏-logo
export var PARTY_SETTING_ITEM_CODE_SUPERVISION_SCREEN_LOGO = 'supervision_screen_logo';
// BI大屏-监管大屏-title
export var PARTY_SETTING_ITEM_CODE_SUPERVISION_SCREEN_TITLE = 'supervision_screen_title';
// 临时巡查-人脸比对
export var PARTY_SETTING_ITEM_CODE_TEMP_TASK_FACE_RECOGNITION = 'work_temp_task_face_recognition';
// 考勤管理-考勤记录
export var PARTY_SETTING_ITEM_CODE_OSS_ATTENDANCE_CLOCK_RECORD = 'oss_attendance_clock_record';
// 考勤管理-班次时间
export var PARTY_SETTING_ITEM_CODE_OSS_ATTENDANCE_CLOCK_CLASSES_TIME = 'oss_attendance_clock_classes_time';
// 考勤管理-今日需打卡时间
export var PARTY_SETTING_ITEM_CODE_OSS_ATTENDANCE_CLOCK_NEED_CLOCK_TIME = 'oss_attendance_clock_need_clock_time';
// 值守管理-隐藏值守时间
export var PARTY_SETTING_ITEM_CODE_GUARDIAN_MANAGEMENT_SHOW_TIME = 'guardian_management_show_time';
// 员工管理-默认密码
export var PARTY_SETTING_ITEM_CODE_PERSONNEL_MANAGEMENT_DEF_PWD = 'personnel_management_def_pwd';
// 首页大屏-logo
export var HOME_SCREEN = 'home_screen_img';
export var startTime = 'start_time';
export var endTime = 'end_time';

/**
 * 个性化设置-个性化设置选项值类型
 * @type {string}
 */
// 文字
export var PARTY_SETTING_ITEM_VALUE_TYPE_TEXT = 'text';
// 图片
export var PARTY_SETTING_ITEM_VALUE_TYPE_IMAGE = 'image';

/**
 * 个性化设置-个性化设置选项布局配置
 * @type {{ valueType: string<PARTY_SETTING_ITEM_VALUE_TYPE_TEXT | PARTY_SETTING_ITEM_VALUE_TYPE_IMAGE> }}
 */
export var PARTY_SETTING_ITEM_VIEW_CONFIG = (_PARTY_SETTING_ITEM_V = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_PARTY_SETTING_ITEM_V, PARTY_SETTING_ITEM_CODE_BILL_QUANTITY_ROLE, {
  remarkVisible: true,
  statusVisible: true,
  valueVisible: false,
  valueType: null
}), PARTY_SETTING_ITEM_CODE_EQUIPMENT_ORDER_ADD, {
  remarkVisible: true,
  statusVisible: true,
  valueVisible: false,
  valueType: null
}), PARTY_SETTING_ITEM_CODE_EQUIPMENT_BASE_INFO, {
  remarkVisible: true,
  statusVisible: true,
  valueVisible: false,
  valueType: null
}), PARTY_SETTING_ITEM_CODE_EQUIPMENT_INSPECT_RECORD, {
  remarkVisible: true,
  statusVisible: true,
  valueVisible: false,
  valueType: null
}), PARTY_SETTING_ITEM_CODE_EQUIPMENT_MAINTENANCE_RECORD, {
  remarkVisible: true,
  statusVisible: true,
  valueVisible: false,
  valueType: null
}), PARTY_SETTING_ITEM_CODE_EQUIPMENT_REPAIRE_RECORD, {
  remarkVisible: true,
  statusVisible: true,
  valueVisible: false,
  valueType: null
}), HOME_SCREEN, {
  remarkVisible: false,
  statusVisible: false,
  valueVisible: true,
  valueType: PARTY_SETTING_ITEM_VALUE_TYPE_IMAGE,
  limit: 1,
  tips: '仅上传一张，建议分辨率1920 X 1080。'
}), PARTY_SETTING_ITEM_CODE_SUPERVISION_SCREEN_TITLE, {
  remarkVisible: false,
  statusVisible: false,
  valueVisible: true,
  valueType: PARTY_SETTING_ITEM_VALUE_TYPE_TEXT,
  maxlength: 15,
  tips: '系统默认值为"服务监督大屏"'
}), PARTY_SETTING_ITEM_CODE_TEMP_TASK_FACE_RECOGNITION, {
  remarkVisible: true,
  statusVisible: true,
  valueVisible: false,
  valueType: null
}), 'scanned', {
  remarkVisible: true,
  statusVisible: true,
  valueVisible: false,
  valueType: null
}), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_PARTY_SETTING_ITEM_V, PARTY_SETTING_ITEM_CODE_SUPERVISION_SCREEN_LOGO, {
  remarkVisible: false,
  statusVisible: false,
  valueVisible: true,
  valueType: PARTY_SETTING_ITEM_VALUE_TYPE_IMAGE,
  limit: 1,
  tips: '仅上传一张，建议分辨率110 X 30。'
}), PARTY_SETTING_ITEM_CODE_OSS_ATTENDANCE_CLOCK_RECORD, {
  remarkVisible: true,
  statusVisible: true,
  valueVisible: false,
  valueType: null
}), PARTY_SETTING_ITEM_CODE_OSS_ATTENDANCE_CLOCK_CLASSES_TIME, {
  remarkVisible: true,
  statusVisible: true,
  valueVisible: false,
  valueType: null
}), PARTY_SETTING_ITEM_CODE_OSS_ATTENDANCE_CLOCK_NEED_CLOCK_TIME, {
  remarkVisible: true,
  statusVisible: true,
  valueVisible: false,
  valueType: null
}), PARTY_SETTING_ITEM_CODE_GUARDIAN_MANAGEMENT_SHOW_TIME, {
  remarkVisible: true,
  statusVisible: true,
  valueVisible: false,
  valueType: null
}), PARTY_SETTING_ITEM_CODE_PERSONNEL_MANAGEMENT_DEF_PWD, {
  remarkVisible: false,
  statusVisible: false,
  valueVisible: true,
  valueType: PARTY_SETTING_ITEM_VALUE_TYPE_TEXT,
  maxlength: 15,
  tips: '系统默认登录密码为"123456"'
}), 'regularization', {
  remarkVisible: true,
  statusVisible: true,
  valueVisible: false,
  valueType: null
}), startTime, {
  remarkVisible: true,
  statusVisible: false,
  valueVisible: true,
  valueType: PARTY_SETTING_ITEM_VALUE_TYPE_TEXT,
  maxlength: 15,
  tips: '默认提前:0小时'
}), endTime, {
  remarkVisible: true,
  statusVisible: false,
  valueVisible: true,
  valueType: PARTY_SETTING_ITEM_VALUE_TYPE_TEXT,
  maxlength: 15,
  tips: '默认延后:72小时'
}), "qr_code_bg_img_resource", {
  remarkVisible: false,
  statusVisible: false,
  valueVisible: true,
  valueType: PARTY_SETTING_ITEM_VALUE_TYPE_IMAGE,
  limit: 1,
  tips: '图片大小：宽624 x 高880，不超过200KB。为了让尺寸更小，建议：简单色彩使用PNG，复杂色彩使用JPG。'
}), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_PARTY_SETTING_ITEM_V, "qr_code_bg_img_device", {
  remarkVisible: false,
  statusVisible: false,
  valueVisible: true,
  valueType: PARTY_SETTING_ITEM_VALUE_TYPE_IMAGE,
  limit: 1,
  tips: '图片大小：宽624 x 高880，不超过200KB。为了让尺寸更小，建议：简单色彩使用PNG，复杂色彩使用JPG。'
}), "qr_code_bg_img_visitor", {
  remarkVisible: false,
  statusVisible: false,
  valueVisible: true,
  valueType: PARTY_SETTING_ITEM_VALUE_TYPE_IMAGE,
  limit: 1,
  tips: '图片大小：宽624 x 高880，不超过200KB。为了让尺寸更小，建议：简单色彩使用PNG，复杂色彩使用JPG。'
}), "qr_code_bg_img_work_order", {
  remarkVisible: false,
  statusVisible: false,
  valueVisible: true,
  valueType: PARTY_SETTING_ITEM_VALUE_TYPE_IMAGE,
  limit: 1,
  tips: '图片大小：宽624 x 高880，不超过200KB。为了让尺寸更小，建议：简单色彩使用PNG，复杂色彩使用JPG。'
}), 'overtime', {
  remarkVisible: true,
  statusVisible: true,
  valueVisible: false,
  valueType: null
}), 'coverShift', {
  remarkVisible: true,
  statusVisible: true,
  valueVisible: false,
  valueType: null
}));

// 最大数字
export var MAX_SAFE_NUMBER = 999999999;