import "core-js/modules/es6.array.find";
import "core-js/modules/web.dom.iterable";
import { constantRouterMap } from '@/router/routers';
import Layout from '@/layout/index';
var permission = {
  state: {
    routers: constantRouterMap,
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: function SET_ROUTERS(state, routers) {
      state.addRouters = routers;
      state.routers = merge(constantRouterMap, routers);
    }
  },
  actions: {
    GenerateRoutes: function GenerateRoutes(_ref, asyncRouter) {
      var commit = _ref.commit;
      commit('SET_ROUTERS', asyncRouter);
    }
  }
};

/**
 * 将默认路由和动态路由进行合并，如果发现新的路由和老路由路径重复则用新的路由覆盖老的路由信息
 * @param constantRouterMap
 * @param routers
 * @returns {[]}
 */
function merge(constantRouterMap, routers) {
  // 1. 创建一个新的路由数组
  var finalRouters = [];

  // 2. 先遍历默认路由 constantRouterMap
  constantRouterMap.forEach(function (defaultRoute) {
    // 查找是否有新路由与默认路由的 path 相同
    var matchingRoute = routers.find(function (route) {
      return route.path === defaultRoute.path;
    });
    if (matchingRoute) {
      // 如果找到相同 path 的路由，用新路由替换旧路由
      finalRouters.push(matchingRoute);
    } else {
      // 如果没有找到相同 path 的路由，保留默认路由
      finalRouters.push(defaultRoute);
    }
  });

  // 3. 添加新路由中没有的路由
  routers.forEach(function (route) {
    var existingRoute = finalRouters.find(function (r) {
      return r.path === route.path;
    });
    if (!existingRoute) {
      finalRouters.push(route); // 如果新路由没有在默认路由里，则添加到最终路由数组
    }
  });
  return finalRouters;
}
var _filterAsyncRouter = function filterAsyncRouter(routers) {
  // 遍历后台传来的路由字符串，转换为组件对象
  return routers.filter(function (router) {
    if (router.component) {
      if (router.component === 'Layout') {
        // Layout组件特殊处理
        router.component = Layout;
      } else {
        var component = router.component;
        router.component = loadView(component);
      }
    }
    if (router.children && router.children.length) {
      router.children = _filterAsyncRouter(router.children);
    }
    return true;
  });
};
export { _filterAsyncRouter as filterAsyncRouter };
export var loadView = function loadView(view) {
  return function (resolve) {
    return require(["@/views/".concat(view)], resolve);
  };
};
export default permission;