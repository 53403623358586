exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "", ""]);

// exports
exports.locals = {
	"menuText": "#f4f4f5",
	"menuActiveText": "#fff",
	"subMenuActiveText": "#fff",
	"menuBg": "#0d4a83",
	"menuHover": "#4da6ff",
	"subMenuBg": "#0b5a95",
	"subMenuHover": "#4da6ff",
	"subMenuActiveBg": "#0060f0",
	"sideBarWidth": "205px",
	"alertColorDefault": "#333",
	"alertColorBlue": "#3557e7",
	"alertColorGreen": "#64b52e",
	"alertColorYellow": "#e3a50f",
	"alertColorRed": "#d74d31"
};