import request from '@/utils/request';
export function getMenusTree() {
  return request({
    url: 'api/menus/tree',
    method: 'get'
  });
}
export function buildMenus(params) {
  return request({
    url: 'api/menus/build',
    method: 'get',
    params: params
  });
}
export function newBuildMenus() {
  return request({
    url: 'api/menus/newBuildMenus',
    method: 'get'
  });
}
export default {
  getMenusTree: getMenusTree,
  buildMenus: buildMenus,
  newBuildMenus: newBuildMenus
};