import Vue from 'vue';
import Router from 'vue-router';
import Layout from "../layout/index";
Vue.use(Router);

/**
 * 默认路由加载，首页配置在默认路由中写死的。
 *    目前会在 {@link handleTopMenuClick} 方法中进行覆盖首页路由配置信息
 * @type {any}
 */
export var constantRouterMap = [{
  path: '/login',
  meta: {
    title: '登录',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/login'], resolve);
  },
  hidden: true
}, {
  path: '/404',
  component: function component(resolve) {
    return require(['@/views/features/404'], resolve);
  },
  hidden: true
}, {
  path: '/401',
  component: function component(resolve) {
    return require(['@/views/features/401'], resolve);
  },
  hidden: true
}, {
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component(resolve) {
      return require(['@/views/features/redirect'], resolve);
    }
  }]
}, {
  path: '/',
  name: 'root',
  // hidden: true, // 隐藏菜单
  component: Layout,
  meta: {
    title: '首页',
    icon: 'index'
  },
  redirect: '/home',
  children: [{
    path: 'home',
    name: 'home',
    component: function component(resolve) {
      return require(['@/views/home'], resolve);
    },
    meta: {
      title: '首页',
      icon: 'yingxiao',
      affix: true
    }
  }]
}, {
  path: '/user',
  component: Layout,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'center',
    component: function component(resolve) {
      return require(['@/views/system-setting/user/center'], resolve);
    },
    name: '个人中心',
    meta: {
      title: '个人中心'
    }
  }]
}, {
  path: '/cloudsee/materia/index',
  name: "images",
  meta: {
    title: '上传图片',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/components/editorMaterial/index'], resolve);
  },
  hidden: true
}, {
  path: '/oa/workflow',
  name: "workflow",
  component: Layout,
  meta: {
    title: '工作流',
    noCache: false
  },
  children: [{
    path: 'index',
    name: 'workflow-index',
    component: function component(resolve) {
      return require(['@/views/oa/workflow/index'], resolve);
    },
    meta: {
      title: '工作流',
      icon: 'yingxiao',
      affix: false,
      noCache: false
    }
  }, {
    path: 'FlowAdmin',
    name: 'workflow-FlowAdmin',
    component: function component(resolve) {
      return require(['@/views/oa/workflow/ProcessDefAdmin.vue'], resolve);
    },
    meta: {
      title: '工作流管理',
      icon: 'yingxiao',
      affix: false,
      noCache: false
    }
  }, {
    path: 'ProcessDefEdit',
    name: 'oa-ProcessDefEdit',
    component: function component(resolve) {
      return require(['@/views/oa/workflow/ProcessDefEdit.vue'], resolve);
    },
    meta: {
      title: '表单定义',
      icon: 'yingxiao',
      affix: false,
      noCache: false
    }
  }, {
    path: 'test',
    name: 'oa-test',
    component: function component(resolve) {
      return require(['@/views/oa/workflow/Test.vue'], resolve);
    },
    meta: {
      title: '表单定义',
      icon: 'yingxiao',
      affix: false,
      noCache: false
    }
  }],
  hidden: true
}];
export default new Router({
  mode: 'history',
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRouterMap
});