import _objectSpread from "/home/opter/repo/code/cloudsee-web/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import router from "./routers";
import store from '@/store';
import Config from '@/settings';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { getToken } from '@/utils/auth';
import { buildMenus, newBuildMenus } from '@/api/system-setting/menu';
import { filterAsyncRouter } from '@/store/modules/permission';
NProgress.configure({
  showSpinner: false
});

// no redirect whitelist
var whiteList = ['/login'];
router.beforeEach(function (to, from, next) {
  if (to.meta.title) {
    document.title = to.meta.title + ' - ' + Config.title;
  }
  NProgress.start();
  if (getToken()) {
    // 已登录且要跳转的页面是登录页
    if (to.path === '/login') {
      next({
        path: '/'
      });
      NProgress.done();
    } else {
      // 判断当前用户是否已拉取完user_info信息
      if (store.getters.roles.length === 0) {
        // 拉取用户所有信息
        store.dispatch('GetInfo').then(function () {
          // 动态路由，拉取菜单
          loadMenus(next, to);
        }).catch(function () {
          store.dispatch('LogOut').then(function () {
            // 为了重新实例化vue-router对象 避免bug
            location.reload();
          });
        });
      } else if (store.getters.loadMenus) {
        console.log("第一次加载菜单");
        // 登录时未拉取 菜单，在此处拉取
        // 修改成false，防止死循环
        store.dispatch('updateLoadMenus').then();
        loadMenus(next, to);
      } else {
        var nextParams = {
          fullPath: to.fullPath,
          path: to.path
        };
        localStorage.setItem("to", JSON.stringify(nextParams));
        next();
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next("/login?redirect=".concat(to.path)); // 否则全部重定向到登录页
      NProgress.done();
    }
  }
});

/**
 * 加载菜单
 *    这里将会加载默认配置的路由。请查看 {@link constantRouterMap} 中定义的默认路由，
 *    当点击顶部菜单时，会重新添加动态路由，具体查看 {@link handleTopMenuClick}。
 * 注: 当系统中的菜单分类为空时加载全部菜单，不为空则按照菜单分类的规则添加
 *
 * @param {Function} next - 导航守卫的下一个回调函数
 * @param {Object} to - 目标路由对象
 *
 * @link /src/router/routers.js#constantRouterMap {@link constantRouterMap}
 * @see handleTopMenuClick
 */
export var loadMenus = function loadMenus(next, to) {
  console.log("加载路由");
  localStorage.removeItem('allMenus');
  localStorage.removeItem('to');
  newBuildMenus().then(function (res) {
    if (res.length > 0) {
      var newMenuItem = {
        categoryId: '1',
        categoryName: '首页',
        menus: []
      };

      // 使用 unshift 将 newMenuItem 插入到数组的第一条
      res.unshift(newMenuItem);
      var allMenus = res.map(function (item) {
        return item.menus;
      }).reduce(function (acc, curr) {
        return acc.concat(curr);
      }, []);
      loadMenuRoute(next, to, filterAsyncRouter(allMenus));
      localStorage.setItem("allMenus", JSON.stringify(res));
    } else {
      buildMenus().then(function (res) {
        loadMenuRoute(next, to, filterAsyncRouter(res));
      });
    }
  });
};

/**
 * 加载菜单路由
 * @param next
 * @param to
 * @param asyncRouter
 */
function loadMenuRoute(next, to) {
  var asyncRouter = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  // 存在分类默认只加载静态路由
  asyncRouter.push({
    path: '*',
    redirect: '/404',
    hidden: true
  });
  store.dispatch('GenerateRoutes', asyncRouter).then(function () {
    // 存储路由
    router.addRoutes(asyncRouter); // 动态添加可访问路由表
    next(_objectSpread(_objectSpread({}, to), {}, {
      replace: true
    }));
  });
}
router.afterEach(function () {
  NProgress.done();
});